import { ComponentSingleUse } from 'types/generated/contentful-types'
import DestinationContinents from '../destinations/DestinationContinents'
import { CommissionCalculator } from './CommissionCalculator'
import {
  AppPreview,
  AppPreviewAdvisorData,
  AppPreviewForaReserveData,
  AppPreviewJoinUsPage,
} from './AppPreview'
import { WeBringYouGet } from './WeBringYouGet'
import TrustPilotWidget from './TrustPilotWidget'

export type SingleUseProps = ComponentSingleUse

const SingleUse = ({ type, sectionId }: SingleUseProps): JSX.Element => {
  return <div id={sectionId || ''}>{renderComponent(type)}</div>
}

const renderComponent = (type) => {
  switch (type) {
    case 'Destination Continents':
      return <DestinationContinents />
    case 'Commission Calculator':
      return <CommissionCalculator />
    case 'App Preview - Advisor Portal':
      return <AppPreview {...AppPreviewAdvisorData} />
    case 'App Preview - Fora Reserve':
      return <AppPreview {...AppPreviewForaReserveData} />
    case 'App Preview - Join Us':
      return <AppPreview {...AppPreviewJoinUsPage} />
    case 'What We Bring/What You Get Infographic':
      return <WeBringYouGet />
    case 'TrustPilot Widget':
      return <TrustPilotWidget />
  }
}

export default SingleUse
