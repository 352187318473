import dynamic from 'next/dynamic'
import React, { useEffect } from 'react'

declare global {
  interface Window {
    Trustpilot: any
  }
}

const TrustPilotWidget = dynamic(() => Promise.resolve(TrustBox), {
  ssr: false,
})

const TrustBox = () => {
  const ref = React.useRef(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <>
      <div
        ref={ref}
        className="mt-6 trustpilot-widget md:mt-9"
        data-locale="en-US"
        data-template-id="5419b732fbfb950b10de65e5"
        data-businessunit-id="61bea15656ae32abb90e3d95"
        data-style-height="24px"
        data-style-width="100%"
      >
        <a
          href="https://www.trustpilot.com/review/foratravel.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}

export default TrustPilotWidget
